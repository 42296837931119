<template>
  <ImportItems :global="true" />
</template>

<script>
import BodyMixin from '@/components/mixins/Body'
import ImportItems from '@/components/importers/ImportItems.vue'

export default {
  mixins: [BodyMixin],
  components: {
    ImportItems
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
