<template>
  <Page title="Import items">
    <SuperImportBody />
  </Page>
</template>

<script>
import PageMixin from '../mixins/Page'
import SuperImportBody from '../bodies/SuperImport.vue'

export default {
  mixins: [PageMixin],
  name: 'SuperImport',
  components: {
    SuperImportBody
  }
}
</script>
